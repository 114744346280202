.response-payment{
    text-align: center;
    margin-top: 25vh;
}
.response-payment-fail{
    text-align: center;
    margin-top: 25vh;
    background: red;
    padding: 40px 0px;
}
.response-bg{
    background: #4D0E0E;
    height: 100vh;
    text-align: center;
}
.response-bg img{
    width: 300px;
    margin-top: 30vh;
}
.payment-loding{
    color: #fff;
    font-size: 70px;
}