:root {
  --green: #008000;
  --darkGreen:#006400;
  --red: #FF0000;
  --darkRed:#8B0000;
  --white: #fff;
  --black: #000;
  --yellow: #ffed00;
  --orange:#FFA500;
  --darkOrange: #FF8C00;
  --pink: #de0021;
  --gold: #FFD700;
  --c: #FF00FF;
  --rebeccaPurple : #663399;
  --LightSeaGreen:#20B2AA;
  --Teal :#008080;
  --DarkBlue:#00008B;
  --Navy:#000080;
  --Maroon:#800000
}